import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "thanks-for-coming-️"
    }}>{`Thanks for coming ❤️`}</h1>
    <p>{`Thanks for coming to my workshop. Hope you enjoyed it and learned a lot. I'm excited to see what you build with these skills :)`}</p>
    <p>{`If you can spare a moment, I'd love to hear your feedback.`}</p>
    <div className="typeform-widget" data-url="https://swizecteller.typeform.com/to/EHfncKVE" data-transparency="50" data-hide-headers="true" data-hide-footer="true" style={{
      "width": "100%",
      "height": "600px"
    }}></div>
    <p>{`No form? Reload the page or `}<a parentName="p" {...{
        "href": "https://swizecteller.typeform.com/to/EHfncKVE"
      }}>{`click here`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      